:root{
	font-size: 16px;
	// color-scheme: dark;
	@media (min-width: 1000px) and (max-width: 1400px) {
			font-size: 12px;
	
		}
		@media (min-width: 1401px) and (max-width: 1600px) {
			font-size: 15px;
		
		}
		@media (min-width: 0px) and (max-width: 375px) {
			font-size: 14px;
	
		}
		@media (min-width: 375px) and (max-width: 425px) {
			font-size: 15px;

		}
		@media (min-width: 425px) and (max-width: 500px) {
			font-size: 16px;
		}
		@media (min-width: 500px) and (max-width: 600px) {
			font-size: 17px;
		}
		@media (min-width: 600px) and (max-width: 700px) {
			font-size: 18px;
		}
		@media (min-width: 700px) and (max-width: 800px) {
			font-size: 19px;
		}
		@media (min-width: 800px) and (max-width: 900px) {
			font-size: 20px;
		}
		@media (min-width: 900px) and (max-width: 999px) {
			font-size: 21px;
		}
}
::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #626982;
}
input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
body {
  margin: 0;
	background: linear-gradient(180deg, #050F1E 0%, #050B1E 100%);
	font-family: 'Rubik', sans-serif;
	width:100%;
	overflow-x:hidden;
	color: #ffffff;
	
}



.lock_width{
	width: 77rem;
	margin: 0 auto;
}

ul,li{
	list-style: none;
}

a{
	text-decoration: none;
	color:#ffffff;
	cursor:pointer;
}

button{
	cursor:pointer;
}

.fixed{

		width: 100vw;
		height: 100vh;
		overflow:hidden;
}
